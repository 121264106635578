@import "../../../App/App.scss";

.masonry {
	ul {
		display: inline-flex;
		flex-wrap: wrap;
		list-style-type: none;
		margin: 0;
	}

	li {
		margin: 0.5rem;
	}

	img {
		height: 280px;
		width: auto;
		object-fit: cover;
		// vertical-align: bottom;
	}
}

.mt-auto {
	margin-top: auto;
}

.pict-actions {
	position: relative;
	top: -5px;
	text-transform: lowercase;
	border-bottom: solid 1px lighten($color: $grey, $amount: 45);
	border-right: solid 1px lighten($color: $grey, $amount: 45);
	border-left: solid 1px lighten($color: $grey, $amount: 45);
	padding: 0.5rem;
}

.suppr-card {
	background-color: $secondary-color;
	border-radius: 7px;
	padding: 0.5rem;
	margin: 1rem 0 1.5rem 0;
	p {
		margin: 0.4rem 0;
	}
}

.suppr-button {
	background-color: $contrast-color;
	color: $white;
	font-family: $text-font;
	@include fluid-type(0.9rem, 1rem);
	border-radius: 8px;
	border: solid 1px $contrast-color;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.3rem 0.7rem;
	margin: 0;
	transition-duration: 0.4s;
	letter-spacing: 0.25px;
}

.suppr-button:hover {
	background-color: $hover-color;
	border: solid 1px $hover-color;
}

.suppr-button:focus {
	outline: 0;
}

.black-overlay {
	position: relative;
	width: 100%;
}

.black-overlay::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 8px;
	right: 0;
	z-index: 1;
	background: rgba(0, 0, 0, 0.4);
}

.photo-items {
	position: relative;
}

.photo-moderation {
	position: absolute;
	z-index: 9;
	right: 0;
	top: 5px;

	h4 {
		font-size: 0.8rem;
		padding: 0.3rem;
		letter-spacing: 0;
	}
}

.bold {
	font-weight: 600;
}

/// PROGRESS BAR

.progress-bar-container {
	height: 3rem;
}

.progress-bar {
	height: 20px;
	width: 100%;
	background-color: lighten($color: $light-grey, $amount: 20);
	border-radius: 7px;
	margin: 0.5rem 0 0 0;
}

.progress-filler {
	height: 100%;
	background-color: $primary-color;
	border-radius: inherit;
	text-align: center;
	font-size: 0.85rem;
}

.progress-label {
	padding: 0;
	color: $white;
	font-weight: 600;
}

//// UPLOAD PICTS FOR PHOTOBOX

.upload-zone {
	width: 100%;
	padding: 1rem;
	background: lighten($color: $secondary-color, $amount: 3);
	border-radius: 7px;
	margin: 1rem 0;
	cursor: pointer;

	.title {
		text-align: center;
	}

	.limit-size {
		display: flex;
		justify-content: flex-start;
		width: 100%;

		.limit-title {
			width: 25%;
			font-weight: 600;
			margin-top: 5px;
		}
		.limit-bar {
			width: 74%;
		}
	}

	.files-zone {
		display: flex;
		justify-content: flex-start;
		list-style-type: none;
		flex-wrap: wrap;
		width: 100%;

		.preview-list {
			background-color: $light-color;
			position: relative;
			width: 31%;
			border-radius: 7px;
			margin: 0.5rem;
			padding: 0.5rem;
		}

		.preview-photobox {
			width: 100%;
			max-width: 125px;
			height: auto;
		}

		.suppr-btn {
			background-color: $contrast-color;
			color: $white;
			@include fluid-type(0.75rem, 0.8rem);
			font-weight: 600;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
			position: absolute;
			top: -5px;
			right: 5px;
			border: solid 1px $light-color;
			padding: 0;
			margin: 0;
			cursor: pointer;
		}

		.suppr-btn:hover {
			background-color: $primary-color;
		}
	}
}

.dropfile-icon {
	width: 2rem;
	height: auto;
	padding: 0;
	margin: 0;
}

.small-button {
	background: $light-color;
	color: $grey;
	font-family: $text-font;
	@include fluid-type(0.75rem, 0.85rem);
	border-radius: 8px;
	border: solid 1px $light-grey;
	text-align: center;
	text-decoration: none;
	padding: 0.2rem 0.5rem;
	margin: 0.2rem 0 0 0;
	transition-duration: 0.4s;
	cursor: pointer;
}

.small-button:hover {
	background-color: $contrast-color;
	color: $white;
	border: solid 1px $contrast-color;
}

.small-button:focus {
	outline: 0;
}

.tabs {
	position: relative;
	display: block;
	margin: 1rem 0 3rem 0;

	.selected-tab {
		border-top: solid 2px $secondary-color;
		border-right: solid 2px $secondary-color;
		border-left: solid 2px $secondary-color;
		border-radius: 7px 7px 0 0;
		background-color: $secondary-color;
		margin: 0 1rem 0 1rem;
		padding: 0.5rem;
		cursor: pointer;
		font-family: $text-font;
		@include fluid-type(0.9rem, 0.95rem);
		font-weight: 600;
	}
	.not-selected-tab {
		border-top: solid 2px $secondary-color;
		border-right: solid 2px $secondary-color;
		border-left: solid 2px $secondary-color;
		border-radius: 7px 7px 0 0;
		background-color: $light-color;
		margin: 0 1rem 0 1rem;
		padding: 0.5rem;
		cursor: pointer;
		font-family: $text-font;
		@include fluid-type(0.9rem, 0.95rem);
	}
	.line-tab {
		position: absolute;
		border-top: solid 2px $secondary-color;
		border-left: solid 2px $secondary-color;
		border-right: solid 2px $secondary-color;
		top: 1.8rem;
		left: 0;
		display: block;
		width: 100%;
		height: 1.25rem;
	}
}

@media screen and (max-width: 700px) {
	.masonry {
		li {
			margin: 0.4rem;
		}
	}
}
