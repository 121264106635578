@import "../../App/App.scss";

.postcard-page {
	width: 100%;
	max-width: 1400px;
}

.postcard-template {
	width: 100%;
	text-align: center;
	border: 1px solid $light-grey;
}

.postcard-template-selected {
	width: 100%;
	border: 2px solid $contrast-color;
	text-align: center;
}

.select-color {
	position: relative;
	display: flex;
}

.color-selection {
	position: absolute;
	top: 3.5rem;
	left: 0;
	border: solid 1px $light-grey;
	border-radius: 7px;
	display: flex;
	flex-wrap: wrap;
	background-color: $white;
	z-index: 99;
	width: 5.2rem;
}

.pick-color-button {
	background-color: $secondary-color;
	border-radius: 7px;
	cursor: pointer;
	padding: 0.5rem;
	margin: 0.25rem;
	text-align: center;
	height: 2.5rem;
	width: 2.5rem;
}

.font-button {
	background-color: $secondary-color;
	border-radius: 7px;
	cursor: pointer;
	padding: 0.5rem;
	margin: 0.25rem;
	text-align: center;
	height: 2.5rem;
	width: 2.5rem;
}

.color-green {
	width: 20px;
	height: 20px;
	background-color: #25ced1;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-black {
	width: 20px;
	height: 20px;
	background-color: #000000;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-purple {
	width: 20px;
	height: 20px;
	background-color: #8367c7;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-brown {
	width: 20px;
	height: 20px;
	background-color: #9c6644;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-corail {
	width: 20px;
	height: 20px;
	background-color: #f95738;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-blue {
	width: 20px;
	height: 20px;
	background-color: #1e96fc;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-pink {
	width: 20px;
	height: 20px;
	background-color: #ff6392;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-yellow {
	width: 20px;
	height: 20px;
	background-color: #fdca40;
	border-radius: 7px;
	margin: 0.2rem;
}

.color-grey {
	width: 20px;
	height: 20px;
	background-color: $light-grey;
	border-radius: 7px;
	margin: 0.2rem;
}

// Templates

//   .one-pict-template-container {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-template-rows: 1fr;
//     gap: 0px 0px;
//     grid-template-areas:
//         ". .";
//     width: 100%;
//     max-width: 1200px;
//     margin: 0 auto;
//     border: solid 1px lighten($color: $light-grey, $amount: 10);
//     margin-top: .5rem;
//     border-radius: 7px;

//     .one-pict-template-pict-container {
//         position: relative;
//         background-color: $secondary-color;
//     }

//     .one-pict-template-pict-container:before {
//         content: "";
//         display: block;
//         height: 0;
//         width: 100%;
//         padding-top:70%;

//     }

//     .one-pict-template-pict {
//         position: absolute;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         right: 0;
//         width: 100%;

//         .pict {
//             object-fit:cover;
//             height: 100%;
//             width: 100%;
//         }
//     }

//     .one-pict-template-text {
//         background-color: lighten($color: $light-grey, $amount: 28);
//         height: 100%;
//         max-height: 600px;
//         position: relative;
//     }

//     .one-pict-template-text:before {
//         content: "";
//         display: block;
//         height: 0;
//         width: 100%;
//         padding-top:70%;
//     }

//     .one-pict-template-content {
//         position: absolute;
//         top: 0;
//         left: 0;
//         padding: 2.5rem 2rem 2rem 2rem;
//         width: 100%;
//     }

//     .one-pict-template-title {
//         text-align: center;
//         text-transform: uppercase;
//         letter-spacing: 1px;
//         font-weight: 600;
//         @include fluid-type(1.05rem, 1.1rem);
//     }

//     .one-pict-template-message {
//         text-align: center;
//         @include fluid-type(0.9rem, 0.95rem);
//     }
// }
