@import "../App/App.scss";

.search-input {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.search-input-icon {
		width: 30px;
		height: auto;
		margin: 0 1rem;
	}
}

.custom-table {
	color: $primary-color;
	font-family: $text-font;
	width: 100%;

	th {
		padding: 1rem;
		border-bottom: solid 5px $secondary-color;
		text-align: left;
		font-weight: 400;
		@include fluid-type(0.95rem, 1rem);
		background-color: lighten($color: $secondary-color, $amount: 3);
	}

	td {
		padding: 0.8rem;
		border-bottom: solid 2px $secondary-color;
		@include fluid-type(0.9rem, 0.95rem);
	}
	tr:hover {
		background-color: lighten($color: $secondary-color, $amount: 3);
	}
}

.table-pagination {
	margin: 1rem 0;
	span {
		color: $primary-color;
		font-size: 1rem;
		font-family: $text-font;
		padding: 0 0.25rem;
	}

	input[type="number"] {
		width: 100%;
		padding: 0.25rem;
		margin: 1rem 0.2rem;
		display: inline-block;
		border: 1px solid lighten($color: $primary-color, $amount: 50%);
		border-radius: 7px;
		box-sizing: border-box;
		background: $light-color;
		font-family: $text-font;
		font-size: 1rem;
		color: $primary-color;
	}

	input[type="number"]:focus {
		outline: 0;
		border: 1px solid $primary-color;
	}

	.table-button {
		background: $light-color;
		color: $primary-color;
		font-family: $text-font;
		font-size: 1.1rem;
		border-radius: 7px;
		border: solid 1px lighten($color: $primary-color, $amount: 50%);
		text-align: center;
		text-decoration: none;
		padding: 0.25rem 0.5rem;
		margin: 1rem 0.2rem;
		transition-duration: 0.4s;
		cursor: pointer;
	}

	.table-button:hover {
		background-color: $secondary-color;
		border: solid 1px $secondary-color;
	}

	.table-button:focus {
		outline: 0;
	}
}
