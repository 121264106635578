@import "../../App/App.scss";

.account-list p {
	padding: 0.3rem 0;
}

.affiliates-list {
	list-style-type: none;
}

.affiliate-form-content {
	display: grid;
	grid-template-columns: 1fr 2fr;
	margin: 0;
}

.affiliate-form-content p {
	margin: 0.5rem 0;
}

.affiliate-link {
	cursor: pointer;
}
.affiliate-link:hover {
	font-weight: 600;
}

.large-qrcode {
	max-width: 300px;
	height: auto;
}

.affiliate-form-content input[type="text"] {
	width: 100%;
	padding: 0.4rem;
	margin: 0.5rem 0;
	display: inline-block;
	border: 1px solid lighten($color: $primary-color, $amount: 50%);
	border-radius: 7px;
	box-sizing: border-box;
	background: $light-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.9rem);
	color: $primary-color;
}

.affiliate-form-content input[type="text"]:focus {
	outline: 0;
	border: 1px solid $grey;
}

.homes {
	list-style-type: none;
}

.familySettings {
	margin: 2rem;
}

.familySettings td {
	min-width: 10rem;
}

// LOGOUT

.logout-button {
	background: $light-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.7rem, 0.9rem);
	border-radius: 8px;
	border: solid 2px $secondary-color;
	text-align: center;
	text-decoration: none;
	padding: 0.5rem;
	margin: 0 1rem;
	transition-duration: 0.4s;
	cursor: pointer;
	min-width: 90%;
}

.logout-button:hover {
	background-color: $secondary-color;
}

.logout-button:focus {
	outline: 0;
}

.remove:hover {
	text-decoration: line-through;
	color: $hover-color;
	transition-duration: 0.4s;
	cursor: pointer;
}

// RESPONSIVE

@media screen and (min-width: 480px) and (max-width: 800px) {
	.profil-card {
		flex-direction: row;
		clear: both;
	}

	.profil-card li {
		display: flex;
		padding: 1rem;
		flex: 1 1 0px;
	}
}
