@font-face {
	font-family: "PoppinsLight";
	src: local("PoppinsLight"), url("./fonts/Poppins/Poppins-Light.ttf") format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: "PoppinsBold";
	src: local("PoppinsBold"), url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
	font-weight: 700;
}

body {
	margin: 0;
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 400;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
