@import "../../App/App.scss";

.flow-activity-emoji {
	width: 100%;
	min-width: 3rem;
	max-width: 5rem;
	.flow-activity-icon {
		width: 100%;
		min-width: 1.5rem;
		max-width: 2.2rem;
		height: auto;
	}
}

.timeline-button {
	background-color: $primary-color;
	color: $white;
	font-family: $text-font;
	font-size: 1rem;
	border-radius: 7px;
	border: none;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.5rem 2rem;
	margin: 0 0 0.5rem 0;
	transition-duration: 0.4s;
	width: 100%;
}

.timeline-button:hover {
	background-color: $hover-color;
}

.timeline-button:focus {
	outline: 0;
}

.white-icon {
	vertical-align: middle;
	color: white;
	width: auto;
	height: 1.6rem;
}

.filter-button {
	background-color: $light-color;
	color: $primary-color;
	font-family: $text-font;
	font-size: 0.9rem;
	border-radius: 7px;
	border: solid 1px lighten($color: $light-grey, $amount: 10);
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.25rem 0;
	margin: 0.75rem 0 0 0;
	transition-duration: 0.4s;
	width: 100%;
}

.filter-button:hover {
	background-color: $secondary-color;
}

.filter-button:focus {
	outline: 0;
}

.reload-button {
	background-color: $secondary-color;
	color: $primary-color;
	font-family: $text-font;
	font-size: 0.9rem;
	border-radius: 7px;
	border: solid 1px $secondary-color;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.25rem 0;
	margin-top: 0.75rem;
	transition-duration: 0.4s;
	width: 100%;
}

.reload-button:hover {
	background-color: $light-color;
	border: solid 1px $light-grey;
}

.reload-button:focus {
	outline: 0;
}

.timeline-icon {
	vertical-align: middle;
	width: 18px;
	height: auto;
	margin-right: 0.5rem;
}

.pt-5 {
	padding-top: 1.2rem;
}

// TIMELINE

.timeline-block {
	margin: 1rem 0;
}

.timeline-category {
	@include fluid-type(1rem, 1.1rem);
	padding: 0;
	font-weight: 400;
	margin: 0 0 0.25rem 0;
}

.timeline-action {
	@include fluid-type(0.9rem, 0.95rem);
	color: $primary-color;
	font-weight: 300;
	letter-spacing: 0.25px;
}

.timeline-healthworker {
	@include fluid-type(0.9rem, 0.95rem);
	color: $grey;
	font-weight: 300;
}

.timeline-container {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.timeline {
	&::before {
		content: "";
		height: 100%;
		width: 2px;
		background-color: $light-grey;
		position: absolute;
		top: 1.8rem;
		left: 6rem;
	}

	.timeline-action {
		.timeline-block {
			display: inline-block;
			position: relative;
			width: 100%;
		}
		.left-timeline {
			margin: 0.75rem 0 0 0;
			padding: 0;
			text-align: center;
			max-width: 4rem;
		}
		.dt-timeline {
			color: $grey;
			@include fluid-type(0.85rem, 0.9rem);
			margin-bottom: 0.5rem;
		}
		.timeline-activity {
			margin: 0.75rem 0 0 8rem;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.day-timeline {
		background-color: $light-grey;
		color: $white;
		margin: 0 0 1.2rem 0;
		padding: 0.5rem;
		border-radius: 7px;
		font-size: 1rem;
		font-weight: 600;
		width: 100%;
		letter-spacing: 0.2px;
	}

	.bullet {
		width: 1.5rem;
		height: 1.5rem;
		box-sizing: border-box;
		border-radius: 50%;
		background: $light-color;
		margin-top: -5rem;
		margin-left: 5.2rem;
		position: absolute;
		box-shadow: 0 0 0 6px $light-color;

		&.beauty {
			background-color: darken($color: $secondary-color, $amount: 30);
			border: 9px solid $secondary-color;
		}
		&.hydratation {
			background-color: $hover-color;
			border: 9px solid lighten($color: $hover-color, $amount: 43);
		}
		&.nutrition {
			background-color: darken($color: #10e0e0, $amount: 10);
			border: 9px solid lighten($color: #10e0e0, $amount: 35);
		}
		&.activity {
			background-color: #fedd02;
			border: 9px solid lighten($color: #fedd02, $amount: 32);
		}
		&.care {
			background-color: #b97cfc;
			border: 9px solid lighten($color: #b97cfc, $amount: 20);
			box-shadow: 0 0 0 5px $light-color;
		}
		&.hygiene {
			background-color: $light-grey;
			border: 9px solid lighten($color: $light-grey, $amount: 20);
		}
		&.visit {
			background-color: #ffc440;
			border: 9px solid lighten($color: #ffc440, $amount: 20);
		}
		&.emergency {
			background-color: #ff0000;
			border: 9px solid lighten($color: #ff0000, $amount: 35);
		}
		&.medecine {
			background-color: #03a89e;
			border: 9px solid #caecd3;
		}
		&.mood {
			background-color: darken($color: $light-color, $amount: 20);
			border: 9px solid darken($color: $light-color, $amount: 8);
		}
	}

	.mood-emoticon-timeline {
		width: 40px;
		height: auto;
		margin: 0 auto;
	}

	.qrcode-timeline {
		width: 52px;
		height: auto;
		margin: 0 auto;
	}

	.info-icon {
		width: 1.5rem;
		height: auto;
		margin-right: 0.4rem;
		vertical-align: middle;
	}
}
