@import "../../../App/App.scss";

.comment-container {
	display: flex;
	margin: 0.75rem 0;
}

.comment-is-after-container {
	display: flex;
	margin: 0.5rem 0 0.5rem 3.2rem;
}

.comment-user-bullet {
	width: 5%;
	min-width: 50px;
	height: 100%;
	min-height: 50px;
	margin: 0.2rem 0;
}

.user-bullet {
	background: $contrast-color;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	text-align: center;
	padding: 5px;
	display: flex;
	height: 35px;
	width: 35px;
	color: $white;
	@include fluid-type(0.95rem, 0.97rem);
	letter-spacing: 1px;
}

.family-bullet {
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	text-align: center;
	padding: 5px;
	display: flex;
	height: 35px;
	width: 35px;
	@include fluid-type(0.95rem, 0.97rem);
	letter-spacing: 1px;
	background: $hover-color;
	color: $white;
}

.comment-card {
	flex-grow: 1;
	border: solid 1px lighten($color: $grey, $amount: 30);
	border-radius: 8px;
	padding: 10px;
}

.comment-answer-btn {
	color: $grey;
	cursor: pointer;
	width: auto;
	height: 0.9rem;
	margin: 0.3rem 0.3rem 0 0;
	vertical-align: middle;
}

.light-separator {
	margin: 0.75rem 0 0.75rem 0;
	width: 50%;
	border-top: solid 1px $secondary-color;
}

.comment-button {
	background: $light-color;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.9rem, 0.92rem);
	border-radius: 8px;
	text-decoration: none;
	padding: 0.2rem 0.6rem;
	margin: 0;
	transition-duration: 0.4s;
	border: solid 1px lighten($color: $light-grey, $amount: 10);
	cursor: pointer;
}

.comment-button:hover {
	background-color: $hover-color;
	color: $white;
}

.comment-button:focus {
	outline: 0;
}
