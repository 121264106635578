@import "../../App/App.scss";

.weather-icon {
	max-width: 7rem;
	height: auto;
}

.weatherSentence {
	margin-top: -12px;
	padding: 0;
}

.weather h2 {
	margin: 1rem 0 0 0;
	padding: 1rem 0;
}

.logo-home {
	width: 100%;
	max-width: 13rem;
	height: auto;
	margin: 0.25rem;
}

.eventsOfTheDay {
	list-style: none;
}

.home-info-separator {
	margin: 0 auto;
	width: 50%;
	border-top: solid 2px $secondary-color;
}
