@import "../App/App.scss";

// Colors from types

.event-type {
	background-color: $contrast-color;
	color: $white;
	border-radius: 4px;
	padding: 0.25rem;
	margin: 0.2rem 0;
	cursor: pointer;
}

.visio-type {
	background-color: $hover-color;
	color: $white;
	border-radius: 4px;
	padding: 0.25rem;
	margin: 0.2rem 0;
	cursor: pointer;
}

.workshop-type {
	background-color: $secondary-color;
	color: $primary-color;
	border-radius: 4px;
	padding: 0.25rem;
	margin: 0.2rem 0;
	cursor: pointer;
}

// CALENDAR

.fc-v-event {
	background-color: transparent !important;
	border: none !important;
}

.fc .fc-daygrid-day.fc-day-today {
	background-color: lighten($color: $secondary-color, $amount: 3) !important;
}

.fc {
	margin-top: 1rem;
	max-width: 1260px;
	margin: 0 auto;
}

.fc .fc-toolbar-title {
	font-size: 1rem !important;
	text-transform: capitalize;
	font-weight: 600;
}

// .fc .fc-button {
// 	display: inline-block;
// 	font-weight: 600;
// 	text-align: center;
// 	vertical-align: middle;
// 	user-select: none;
// 	background-color: transparent;
// 	border: 1px solid transparent;
// 	padding: 0.5rem;
// 	font-size: 0.9em;
// 	line-height: 1.25;
// 	border-radius: 0.2em;
// }
