@import "../../App/App.scss";

.social-card {
	border: solid 2px $secondary-color;
	margin: 0.5rem 0;
	padding: 0;
	// box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	position: relative;
	h3 {
		margin: 1rem 0.75rem 0.25rem 0.75rem;
	}
	.blogpost-title {
		cursor: pointer;
	}
	.blogpost-title:hover {
		color: $hover-color;
	}
	ul {
		margin: 1.75rem;
	}
}

.read-more-post {
	vertical-align: middle;
	width: auto;
	height: 1.3rem;
	display: inline;
	cursor: pointer;
	color: $hover-color;
	position: absolute;
	top: 1.25rem;
	right: 0.75rem;
}

.social-info {
	margin: 0 0 1rem 0.75rem;
}

.ml-5 {
	margin-left: 15px;
}

.pict {
	display: block;
	width: 100%;
	height: 20rem;
	object-fit: cover;
}

.pict-container {
	display: inline;
}

.inline-picts {
	width: 50%;
	height: 20rem;
	object-fit: cover;
	padding: 0 0.05rem;
}

.social-text {
	font-family: $title-font;
	color: $primary-color;
	font-weight: 400;
	padding: 1.25rem 0.75rem;
	@include fluid-type(0.85rem, 0.9rem);
	line-height: 1.35rem;
}

.social-text-link {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(0.85rem, 0.9rem);
	line-height: 1.5rem;
	letter-spacing: 0;
	cursor: pointer;
	font-weight: 600;
}

.social-text-link:hover {
	color: $hover-color;
}

.social-separator {
	border-top: solid 1px $secondary-color;
	padding: 0;
	margin: 0;
}

.social-icon {
	vertical-align: middle;
	width: auto;
	height: 1.5rem;
	margin-right: 0.5rem;
}

.social-like-icon {
	vertical-align: middle;
	width: auto;
	height: 1.75rem;
	color: $contrast-color;
	cursor: pointer;
	margin-right: 0.5rem;
}

.social-like-icon:hover {
	color: $hover-color;
}

// .social-liked-icon {
// 	vertical-align: middle;
// 	width: auto;
// 	height: 1.5rem;
// 	color: $contrast-color;
// 	cursor: pointer;
// 	margin-right: 0.5rem;
// }

.social-container {
	margin: 0.7rem 0;
	display: flex;
}

.social-box {
	width: 50%;
}

.column {
	float: left;
	width: 50%;
	padding: 0.5rem;
}

.row:after {
	content: "";
	display: table;
	clear: both;
}

.big-column {
	float: left;
	width: 70%;
	padding: 0.5rem;
}

.small-column {
	display: inline-block;
	width: 30%;
	padding: 0.5rem;
	position: relative;
	height: 5.5rem;
}

.center-column {
	vertical-align: middle;
	height: 2rem;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0;
}

.news {
	@include fluid-type(0.89rem, 0.91rem);
	font-weight: 400;
	cursor: pointer;
	padding: 0;
}

.news:hover {
	background-color: $secondary-color;
}

.news-list {
	margin: 0.5rem;
	list-style: none;

	li {
		padding: 0.75rem 0;
		border-bottom: solid 1px $secondary-color;
	}
}

.full-pict {
	width: 100%;
	max-width: 780px;
	height: auto;
	margin: 0.5rem 0 0 0;
	display: block;
}

.calendar-icon {
	vertical-align: middle;
	width: 20px;
	height: auto;
	padding-right: 0.25rem;
}

.like-card {
	border-top: solid 1px $secondary-color;
	border-bottom: solid 1px $secondary-color;
	margin: 0.5rem auto;
	padding: 0.5rem;
	text-align: center;
}

.public-DraftStyleDefault-ltr {
	margin: 0.5rem 0;
}

.video-container {
	// display: inline-block;
	width: 100%;
	max-width: 40rem;
	margin: 0 auto;
	border: solid 1px $light-grey;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pictures-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ribbon {
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon span {
	@include fluid-type(0.8rem, 0.85rem);
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	text-align: center;
	line-height: 20px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 100px;
	display: block;
	background: $hover-color;
	position: absolute;
	top: 19px;
	right: -21px;
}
.ribbon span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid $primary-color;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid $primary-color;
}
.ribbon span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #1e5799;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #1e5799;
}
.single-post-pict {
	position: relative;
	.single-post-pict-download {
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 3rem;

		.single-post-pict-download-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			color: white;
		}
		.single-post-pict-download-icon {
			height: 1.5rem;
			width: auto;
		}
	}
}

@media screen and (max-width: 800px) {
	.fixed-card {
		display: none;
	}
}
