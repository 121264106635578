@import "../../App/App.scss";

// Responsive Nav

/*Desktop View*/
.header {
	display: flex;
	background: lighten($color: $secondary-color, $amount: 2);
	justify-content: space-between;
	align-items: center;
	padding: 0 25px 0 0;
	height: 80px;

	.nav-option-link {
		color: $primary-color;
		@include fluid-type(0.9rem, 0.95rem);
		letter-spacing: 0;
	}
	.nav-option-link-selected {
		color: $hover-color;
		@include fluid-type(0.9rem, 0.95rem);
		letter-spacing: 0;
	}
	.nav-option-link:hover {
		color: $hover-color;
	}
}

.nav-option-notification {
	position: relative;
}

.link-notification {
	position: absolute;
	top: 10px;
	left: 8px;
}

.link-notification-icon {
	width: auto;
	height: 1.2rem;
}

.responsive-link-notification-counter {
	position: absolute;
	top: 40%;
	left: 38%;
	right: 62%;
	transform: translate(-50%, -50%);
	color: $white;
	@include fluid-type(0.62rem, 0.66rem);
	font-weight: 600;
	font-family: $text-font;
	padding: 0;
}

.right-menu {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}
.nav-options {
	display: grid;
	grid-template-columns: repeat(5, auto);
	grid-gap: 17px;
	list-style-type: none;
}
.mobile-option {
	display: none;
}

.mobile-menu {
	display: none;
}
@media (max-width: 790px) {
	/*Mobile View */
	.header {
		padding: 0 12px 0 0;

		.nav-option-link {
			color: $primary-color;
			@include fluid-type(1rem, 1.2rem);
			letter-spacing: 0;
		}
		.nav-option-link-selected {
			color: $hover-color;
			@include fluid-type(1rem, 1.2rem);
			letter-spacing: 0;
		}
		.nav-option-link:hover {
			color: $hover-color;
		}
		.nav-icon {
			vertical-align: middle;
			width: 24px;
			height: auto;
			margin-right: 0.5rem;
			display: inline;
		}
	}

	.nav-options {
		display: flex;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 85px;
		left: -100%;
		opacity: 0;
		transition: all 0.5s ease;
		flex-direction: column;
		list-style-type: none;
		grid-gap: 0px;
	}

	.nav-options.active {
		background: lighten($color: $secondary-color, $amount: 2);
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 9;
		align-content: center;
		padding-left: 0px;
	}

	.menu-icon {
		width: 45px;
		height: 45px;
		cursor: pointer;
	}
	.option {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 10vw;
		padding: 30px 0px;
	}
}
.mobile-menu {
	display: block;
}

///////////////////////

.main-nav {
	margin: 0;
	padding: 0;
	position: fixed;
	z-index: 98;
	width: 100%;
	top: 0;
}

.nav {
	background: lighten($color: $secondary-color, $amount: 1);
	padding: 0.25rem 0.7rem 0 0.25rem;
	margin: 0;
}

.nav-header-notification {
	position: relative;
}

.link-notification-counter {
	position: absolute;
	top: 35%;
	left: 40%;
	right: 50%;
	transform: translate(-40%, -40%);
	color: $white;
	@include fluid-type(0.58rem, 0.62rem);
	font-weight: 600;
	font-family: $text-font;
	padding: 0;
}

#senior-nav {
	display: flex;
	justify-content: center;
	align-content: center;
	list-style: none;
	background-color: $light-color;
	z-index: 90;
	margin: 0;
	padding: 0;
}

#senior-nav li {
	margin: 1rem 0.5rem;
	height: 100%;
	display: flex;
	align-items: center;
}

.visio-planned-button {
	border: solid 2px $hover-color;
	border-radius: 50px;
	padding-right: 1rem;
	vertical-align: middle;
	position: relative;
	width: 12rem;
}

.start-visio-button {
	border: solid 2px $contrast-color;
	border-radius: 50px;
	padding-right: 1rem;
	vertical-align: middle;
	position: relative;
	width: 12rem;
}
.senior-nav-name {
	@include fluid-type(0.82rem, 0.88rem);
	margin: 0;
	line-height: 1rem;
	letter-spacing: -0.25px;
}
.selected-senior {
	border: solid 2px darken($color: $secondary-color, $amount: 10);
	border-radius: 50px;
	padding-right: 1rem;
	vertical-align: middle;
	position: relative;
	width: 12rem;
}

.other-senior {
	border: solid 2px lighten($color: $primary-color, $amount: 55);
	border-radius: 50px;
	padding-right: 1rem;
	position: relative;
	width: 12rem;
	cursor: pointer;

	img {
		-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
		filter: grayscale(100%);
	}

	.senior-nav-name {
		color: lighten($color: $primary-color, $amount: 35);
	}
}
.other-senior:hover {
	border: solid 2px lighten($color: $primary-color, $amount: 35);

	img {
		-webkit-filter: grayscale(5%); /* Safari 6.0 - 9.0 */
		filter: grayscale(5%);
	}
	.senior-nav-name {
		color: $primary-color;
	}
}

.visio-time-icon {
	background-color: $hover-color;
	border-radius: 50px;
	width: auto;
	height: 2.5rem;
	padding: 0.5rem;
	margin-right: 0.5rem;
}

.visio-start-icon {
	background-color: $contrast-color;
	border-radius: 50px;
	width: auto;
	height: 2.5rem;
	padding: 0.5rem;
	margin-right: 0.5rem;
}

.avatar-sidebar {
	width: 100%;
	max-width: 4rem;
	min-width: 3rem;
	height: auto;
}

.space-between {
	display: flex;
	justify-content: space-between;
	padding: 0;
	margin: 0;
}

.d-flex {
	display: flex;
}

.responsive-sidebar {
	display: flex;
	justify-content: space-between;
	margin: 0.4rem 0;
}

.sidebar-unselected-senior-card {
	border: solid 2px lighten($color: $light-grey, $amount: 12);
	border-radius: 7px;
	margin: 0.4rem;
	padding: 0.75rem;
	position: relative;
	cursor: pointer;

	img {
		-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
		filter: grayscale(100%);
	}
}
.sidebar-unselected-senior-card:hover {
	border: solid 2px $hover-color;

	img {
		-webkit-filter: grayscale(5%); /* Safari 6.0 - 9.0 */
		filter: grayscale(5%);
	}
}

.sidebar-responsive-card {
	border: solid 2px $secondary-color;
	border-radius: 7px;
	margin: 0;
	position: relative;
	width: 100%;
	padding: 0.2rem;
}

.sidebar-responsive-shortcuts {
	width: 100%;
	margin-right: 0.5rem;
}

.sidebar-responsive-card-unselected {
	border: solid 2px lighten($color: $grey, $amount: 47);
	border-radius: 7px;
	margin-left: 0.3rem;
	text-align: center;
	cursor: pointer;
	padding: 0;
	display: table;
	position: relative;

	p {
		@include fluid-type(0.85rem, 0.89rem);
		color: $light-grey;
		line-height: 1rem;
		display: table-cell;
		vertical-align: middle;
	}
}
.sidebar-responsive-card-unselected:hover {
	border: solid 2px $hover-color;

	p {
		color: $primary-color;
	}
}

.shorcuts-sidebar {
	display: flex;
	justify-content: space-between;

	.shortcut-icon {
		width: auto;
		height: 1.4rem;
		margin: 0.3rem 0 0 0;
		cursor: pointer;
		color: $primary-color;
	}
	.shortcut-icon:hover {
		color: $hover-color;
	}
}

.next-visio-icon {
	vertical-align: middle;
	&.blue {
		width: auto;
		height: 1.5rem;
		color: $hover-color;
	}
	&.red {
		width: auto;
		height: 2rem;
		color: $contrast-color;
	}
}

.avatar-nav {
	width: 3rem;
	height: auto;
	padding-right: 0.5rem;
}

.nav-link p:hover {
	color: $hover-color;
}

.nav-icon {
	vertical-align: middle;
	width: auto;
	height: 21px;
	padding-right: 0.3rem;
	display: inline;
}

#logo {
	width: 8rem;
	height: auto;
	margin-left: 1.2rem;
	cursor: pointer;
	image-rendering: -webkit-optimize-contrast;
}

#amd-logo {
	width: auto;
	height: 5rem;
	margin: 0 0 0 1.4rem;
	padding: 0;
	cursor: pointer;
	// image-rendering: -webkit-optimize-contrast;
}

.senior-notification {
	position: absolute;
	top: -5px;
	right: 10px;
	height: 1rem;
	width: auto;
	&.grey {
		color: $light-grey;
	}
}

.mailbox-notification {
	position: absolute;
	top: -5px;
	right: 5px;
	height: 1rem;
	width: auto;
}

// RESPONSIVE TOOLBAR

nav.responsive-toolbar {
	height: 60px;
	// width: 100%;

	// & ~ * {
	//     padding-top: 60px;
	// }

	ul {
		display: flex;
		height: 100%;
		justify-content: flex-end;
		align-items: center;
		// background: lighten($color: $secondary-color, $amount: 1);
		// margin-right: 1rem;

		figure {
			display: none;
		}

		li {
			// display: flex;
			list-style-type: none;
			padding: 10px;
		}

		a {
			color: $primary-color;
			text-decoration: none;
			display: flex;
			align-items: center;
		}

		i {
			margin-right: 5px;
			font-size: 20px;
		}
	}
}
@media screen and (max-width: 768px) {
	#logo {
		margin-left: 0.5rem;
	}

	.shorcuts-sidebar {
		display: flex;
		justify-content: flex-start;
		.shortcut-icon {
			width: auto;
			height: 1.3rem;
			margin: 0.25rem 1.1rem 0 0;
		}
	}
}

@media screen and (max-width: 350px) {
	.shorcuts-sidebar {
		.shortcut-icon {
			width: auto;
			height: 1.2rem;
			margin: 0.2rem 0.3rem 0 0;
		}
	}
}
