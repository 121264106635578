@import "../../App/App.scss";

.mood-card {
	margin: 0.25rem 0;
	border: solid 2px $secondary-color;
	border-radius: 8px;
	padding: 1rem;
	background: $light-color;
}

.moodweek {
	display: flex;
	justify-content: space-around;
	align-content: center;
	padding: 0.5rem;
	// flex: 1;
	list-style: none;
	flex-wrap: wrap;
}

.mood-emoticon {
	width: 3.25rem;
	height: auto;
	margin: 0.25rem;
}

@media screen and (max-width: 600px) {
	.mood-emoticon {
		width: 2.25rem;
	}
}
